@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 98%;
    --foreground: 222.2 84% 4.9%;

    --highlight-bg-color: #2c3e50; /* Darker background color */
    --highlight-text-color: #ecf0f1; /* Lighter text color */

    --card: 0 0% 98%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 98%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 8%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 8%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 8%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

.rdp {
  --rdp-cell-size: 32px;  /* Adjust this value as needed */
  --rdp-accent-color: hsl(var(--primary));
  --rdp-background-color: hsl(var(--background));
}

.rdp-month {
  width: 100%;
}

.rdp-table {
  width: 100%;
  margin: 0;
}

.rdp-head_cell,
.rdp-cell {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;
}

.rdp-head_cell {
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.rdp-button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}