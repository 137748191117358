/* authorizer-custom.css */
.dark .authorizer-root {
  --authorizer-primary-color: #3b82f6; /* Tailwind blue-500 */
  --authorizer-primary-disabled-color: #60a5fa; /* Tailwind blue-400 */
  --authorizer-gray-color: #9ca3af; /* Tailwind gray-400 */
  --authorizer-white-color: #1f2937; /* Tailwind gray-800 */
  --authorizer-danger-color: #ef4444; /* Tailwind red-500 */
  --authorizer-success-color: #10b981; /* Tailwind green-500 */
  --authorizer-text-color: #f3f4f6; /* Tailwind gray-100 */
}

.dark .authorizer-root input,
.dark .authorizer-root select {
  background-color: #374151; /* Tailwind gray-700 */
  color: #f3f4f6; /* Tailwind gray-100 */
  border-color: #4b5563; /* Tailwind gray-600 */
}

.dark .authorizer-root input::placeholder {
  color: #9ca3af; /* Tailwind gray-400 */
}

.dark .authorizer-root label,
.dark .authorizer-root p {
  color: #f3f4f6; /* Tailwind gray-100 */
}

.dark .authorizer-root button {
  background-color: #3b82f6; /* Tailwind blue-500 */
  color: #ffffff;
}

.dark .authorizer-root button:hover {
  background-color: #2563eb; /* Tailwind blue-600 */
}

.dark .authorizer-root .checkbox-container {
  color: #f3f4f6; /* Tailwind gray-100 */
}
